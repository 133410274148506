import {
  Accordion,
  Button,
  Checkbox,
  Form,
  FormField,
  Label,
  Modal,
  ModalContent,
  Table,
} from "semantic-ui-react";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useActions } from "../../redux/actions/UseActions";
import * as QueryActions from "../../redux/actions/QueryActions";
import { getQueryData } from "../../redux/actions/QueryActions";
import TableDataView from "./TableDataView";
import Loader from "../Loader";

interface Props {
  query: any;
  onClose: () => void;
}

const ViewQueryModal: React.FC<Props> = ({ query, onClose }) => {
  const queriesAction = useActions(QueryActions);
  const [panels, setPanels] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadResults();
  }, []);

  const loadResults = async () => {
    const data: any[] = await queriesAction.getQueryData(query.queryNo);
    const panels = data
      .sort((a, b) => a.practice?.practice?.localeCompare(b.practice?.practice))
      .map((row) => ({
        key: row.id,
        title: {
          content: (
            <>
              {row.practice?.practice ?? "Data"}
              <Label
                color="blue"
                className="float-right"
                detail="Rows"
                content={row.data?.length}
              />
            </>
          ),
        },
        content: { content: <TableDataView data={row.data} /> },
      }));
    setPanels(panels);
    setLoading(false);
  };

  return (
    <>
      <Modal closeIcon open={true} size="fullscreen" onClose={() => onClose()}>
        <Modal.Header content={query.name} />
        <Modal.Content scrolling>
          {loading && <Loader />}
          <Accordion panels={panels} fluid styled />
        </Modal.Content>
      </Modal>
    </>
  );
};

export default ViewQueryModal;
