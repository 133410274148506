import { convertToQueryParams } from "../../utils/converter";

export const getQueries =
  (data: any) => async (dispatch: Function, _getState: Function, api: any) => {
    try {
      const queryParams = convertToQueryParams(data).replace(/where\./g, "");
      return await api.get(`queries?${queryParams}`);
    } catch (err) {
      console.error(err);
      return null;
    }
  };

export const submitQuery =
  (payload: any) =>
  async (dispatch: Function, _getState: Function, api: any) => {
    try {
      const result = await api.post(`queries`, payload);
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

export const runQuery =
  (queryNo: number) =>
  async (dispatch: Function, _getState: Function, api: any) => {
    try {
      const result = await api.post(`queries/${queryNo}`);
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

export const getQueryData =
  (queryNo: number) =>
  async (dispatch: Function, _getState: Function, api: any) => {
    try {
      return await api.get(`queries/${queryNo}`);
    } catch (err) {
      console.error(err);
      return [];
    }
  };
