import Template from "../components/Template";
import { useActions } from "../redux/actions/UseActions";
import * as QueryActions from "../redux/actions/QueryActions";
import * as PracticeActions from "../redux/actions/PracticeActions";
import React, { useEffect, useState } from "react";
import SubmitQueryModal from "../components/queries/SubmitQueryModal";
import {
  Button,
  Grid,
  Icon,
  Label,
  LabelGroup,
  Table,
} from "semantic-ui-react";
import copy from "copy-to-clipboard";
import ViewQueryModal from "../components/queries/ViewQueryModal";
import { HelpMark } from "../components/help/HelpMark";
import { HELP_PAGE } from "../utils/HELP_PAGE";

const Queries = () => {
  const queriesAction = useActions(QueryActions);
  const practiceActions = useActions(PracticeActions);
  const [query, setQuery] = useState<any>();
  const [queries, setQueries] = useState<any[]>();
  const [practices, setPractices] = useState<any[]>();
  const [viewQuery, setViewQuery] = useState<any>();
  const [loading, setLoading] = useState<any>(false);

  useEffect(() => {
    loadQueries();
  }, [query]);

  const loadQueries = async () => {
    setLoading(true);
    const data = await queriesAction.getQueries({});
    const practices = await practiceActions.getPractices();
    setQueries(data);
    setLoading(false);
    setPractices(practices.data);
  };

  const runQuery = async (queryNo: number) => {
    setLoading(true);
    await queriesAction.runQuery(queryNo);
    await loadQueries();
  };

  return (
    <Template activeLink="queries" loading={loading}>
      <br />
      <Grid>
        <Grid.Row>
          <Grid.Column width={10}>
            <h1>
              <span className="mr-2">Queries</span>
              <HelpMark helpPage={HELP_PAGE.Queries} />
            </h1>
          </Grid.Column>
          <Grid.Column width={6}>
            <div className="text-right mb-1">
              <Button
                content="Create Query"
                primary
                onClick={() => setQuery({})}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <br />
      {query && (
        <SubmitQueryModal
          practices={practices ?? []}
          query={query}
          onClose={() => setQuery(undefined)}
        />
      )}

      {viewQuery && (
        <ViewQueryModal
          query={viewQuery}
          onClose={() => setViewQuery(undefined)}
        />
      )}

      <Table striped size="small" compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Query No.</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Server</Table.HeaderCell>
            <Table.HeaderCell width={10}>SQL</Table.HeaderCell>
            <Table.HeaderCell content="Runs" />
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {(queries ?? []).map((query) => (
            <Table.Row>
              <Table.Cell content={query.queryNo} />
              <Table.Cell content={query.name} />
              <Table.Cell content={query.server.toUpperCase()} />
              <Table.Cell className="ellipsis-50 font-monospace">
                <Button
                  size="tiny"
                  icon
                  primary
                  onClick={() => copy(query.sql)}
                >
                  <Icon name="copy outline" />
                </Button>
                {query.sql}
              </Table.Cell>
              <Table.Cell content={query.runs.length} />
              <Table.Cell>
                <Button
                  size="tiny"
                  basic
                  icon
                  primary
                  onClick={() => setQuery(query)}
                >
                  <Icon name="edit outline" />
                </Button>
                <Button
                  content="Run Query"
                  color="green"
                  size="tiny"
                  basic
                  onClick={() => runQuery(query.queryNo)}
                />
                <Button
                  content={`View Data`}
                  size="tiny"
                  onClick={() => setViewQuery(query)}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Template>
  );
};

export default Queries;
