import { Button, Dropdown, Form, Modal } from "semantic-ui-react";
import React, { ChangeEvent, useState } from "react";
import { useActions } from "../../redux/actions/UseActions";
import * as QueryActions from "../../redux/actions/QueryActions";

interface Props {
  query: any;
  practices: any[];
  onClose: () => void;
}

const SubmitQueryModal: React.FC<Props> = ({ query, onClose, practices }) => {
  const queriesAction = useActions(QueryActions);
  let queryForm = {};
  if (query) {
    queryForm = { ...query, practices: (query.practices ?? "").split(";") };
  }
  const [form, setForm] = useState<any>(queryForm);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    const data = { ...form, practices: (form.practices ?? []).join(";") };
    const result = await queriesAction.submitQuery(data);
    if (!result) {
      alert("An error occurred");
      return;
    }
    onClose();
  };

  const onPracticeSelected = (value: any) => {
    const lastValue = value[value.length - 1];
    let practices;
    if (lastValue == "all") {
      practices = ["all"];
    } else if (value[0] == "all" && value.length > 1) {
      practices = value.slice(1, value.length);
    } else {
      practices = value;
    }

    setForm({ ...form, practices });
  };

  return (
    <>
      <Modal open={true} size="fullscreen" onClose={() => onClose()}>
        <Modal.Content>
          <h2 className="text-center">Submit Query</h2>
          <Form>
            <Form.Group widths="equal">
              <Form.Field>
                <label>Query No (Unique)</label>
                <input
                  onChange={handleChange}
                  placeholder="Query No"
                  name="queryNo"
                  value={form.queryNo}
                />
              </Form.Field>
              <Form.Field>
                <label>Query Name</label>
                <input
                  value={form.name}
                  name="name"
                  onChange={handleChange}
                  placeholder="Query Name"
                />
              </Form.Field>
              <Form.Field>
                <label>Select Server</label>
                <Dropdown
                  search
                  required
                  placeholder="Select Server"
                  fluid
                  selection
                  options={[
                    { text: "CDP", value: "cdp" },
                    { text: "Open Dental", value: "open_dental" },
                  ]}
                  value={form.server}
                  onChange={(e, d) => setForm({ ...form, server: d.value })}
                />
              </Form.Field>
              <Form.Field>
                {form.server == "open_dental" && (
                  <>
                    <label>Select Practices</label>
                    <Dropdown
                      search
                      required
                      placeholder="Select Practices"
                      fluid
                      multiple
                      selection
                      options={[{ text: "ALL", value: "all" }].concat(
                        practices
                          .map((p) => ({ text: p.practice, value: `${p.id}` }))
                          .sort((a, b) => a.text.localeCompare(b.text)),
                      )}
                      value={form.practices}
                      onChange={(e, d) => onPracticeSelected(d.value)}
                    />
                  </>
                )}
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <textarea
                className="font-monospace"
                onChange={handleChange}
                rows={25}
                name="sql"
                value={form.sql}
                placeholder="Insert SQL query here"
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default SubmitQueryModal;
