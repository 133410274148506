import React from "react";
import { Table } from "semantic-ui-react";

interface IProps {
  data: any[];
}

const TableDataView: React.FC<IProps> = ({ data }) => {
  if (!data?.length)
    return (
      <p className="text-center">
        <i>No data found or query failed to run successfully</i>
      </p>
    );

  return (
    <Table size="small">
      <Table.Header>
        <Table.Row>
          {Object.keys(data[0]).map((key) => (
            <Table.HeaderCell>{key}</Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data.slice(0, 10).map((row, i) => (
          <Table.Row key={"key" + i}>
            {Object.keys(data[0]).map((key) => (
              <Table.Cell>{row[key]}</Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default TableDataView;
